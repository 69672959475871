import { IItemMenu } from '@sp-shared/components/nav/models/item-menu-model';
import { NavItemMenuGroupTypesEnum } from '@sp-core/agreement-keys/nav-item-menu-group-types.enum';

export const leftMenu: IItemMenu[] = [
  {
    link: 'home',
    name: 'NAV.HOME',
    whoCanView: ['dashboard'],
    key: 'home',
  },
  {
    link: 'patients',
    name: 'NAV.PATIENTS',
    whoCanView: ['patientList'],
    key: 'patients',
  },
  // {
  //   link: 'consult',
  //   name: 'NAV.PATIENT_CONSULTS',
  //   whoCanView: ['userConsultRequests'],
  //   key: 'patients-consults',
  // },
  // {
  //   link: 'medical-questions',
  //   name: 'NAV.GENERAL_QUESTIONS',
  //   whoCanView: ['medicalQuestionsCreate'],
  //   key: 'medical-questions',
  // },
  {
    whoCanView: ['userConsultRequests'],
    dropMenuGroup: NavItemMenuGroupTypesEnum.questionsAndConsultProviderRole,
    dropMenuGroupName: 'NAV.QUESTIONS_AND_CONSULTS',
    isLimitRoleAccessItem: false,
    items: [
      {
        link: 'medical-questions',
        name: 'NAV.GENERAL_QUESTIONS',
        whoCanView: ['medicalQuestionsCreate'],
        key: 'medical-questions',
      },
      {
        link: 'consult',
        name: 'NAV.PATIENT_CONSULT',
        whoCanView: ['userConsultRequests'],
        key: 'patients-consults',
      },
    ],
  },
  {
    link: 'membership-fees',
    name: 'NAV.MEMBERSHIP_FEES',
    whoCanView: ['paymentCheckout'],
    key: 'membership-fees',
  },
  {
    link: 'insertion-note-templates',
    name: 'NAV.INSERTION_NOTE_TEMPLATE',
    whoCanView: ['insertionNoteTemplates', 'insertionNoteTemplatesOwn'],
    key: 'insertion-note-templates',
  },
  {
    link: 'admin/dashboard',
    name: 'NAV.HOME',
    whoCanView: ['practiceOwn'],
    key: 'home',
  },
  {
    link: 'admin/practices',
    name: 'NAV.PRACTICE',
    whoCanView: ['practiceList'],
    key: 'admin-practices',
  },
  {
    link: 'admin/own-practice',
    name: 'NAV.OWN_PRACTICE',
    whoCanView: ['practiceOwn'],
    key: 'admin-own-practice',
  },
  {
    whoCanView: ['practiceOwn'],
    dropMenuGroup: NavItemMenuGroupTypesEnum.usersAndRolesForPracticeOwn,
    dropMenuGroupName: 'NAV.USERS_AND_ROLES',
    isLimitRoleAccessItem: true,
    items: [
      {
        link: 'admin/practice/employees',
        name: 'NAV.EMPLOYEES',
        whoCanView: ['employeesOwn'],
        key: 'employees',
      },
      {
        link: 'admin/roles',
        name: 'NAV.ROLES_PERMISSION',
        whoCanView: ['practiceOwn'],
        key: 'admin-roles',
        isLimitRoleAccess: true,
      },
    ],
  },
  {
    whoCanView: ['practiceList'],
    dropMenuGroup: NavItemMenuGroupTypesEnum.usersAndRoles,
    dropMenuGroupName: 'NAV.USERS_AND_ROLES',
    isLimitRoleAccessItem: false,
    items: [
      {
        link: 'admin/users',
        name: 'NAV.EMPLOYEES',
        whoCanView: ['practiceList'],
        key: 'users',
      },
      {
        link: 'admin/roles',
        name: 'NAV.ROLES_PERMISSION',
        whoCanView: ['rolesAndPermissions'],
        key: 'admin-roles',
      },
    ],
  },
  {
    link: 'admin/reports',
    name: 'NAV.REPORTS',
    whoCanView: ['reportsList'],
    key: 'admin-reports',
  },
  {
    link: 'admin/activities',
    name: 'NAV.ACTIVITIES',
    whoCanView: ['activityLogList'],
    key: 'admin-activities',
  },
  {
    whoCanView: ['medicalQuestionsAssign'],
    dropMenuGroup: NavItemMenuGroupTypesEnum.questionsAndConsult,
    dropMenuGroupName: 'NAV.QUESTIONS_AND_CONSULTS',
    isLimitRoleAccessItem: false,
    items: [
      {
        link: 'admin/medical-questions',
        name: 'NAV.GENERAL_QUESTIONS',
        whoCanView: ['medicalQuestionsAssign'],
        key: 'admin-medical-questions',
      },
      {
        link: 'admin/consult',
        name: 'NAV.PATIENT_CONSULT',
        whoCanView: ['consultationList'],
        key: 'admin-patient-consult',
      },
    ],
  },
  {
    link: 'cerbo/integrations',
    name: 'NAV.CERBO',
    whoCanView: ['cerboIntegrations'],
    key: 'cerbo',
  },
  {
    link: 'admin/referrals',
    name: 'NAV.REFERRALS',
    whoCanView: ['referralsContact', 'referralsAssign'],
    key: 'admin-referrals',
  },
  {
    whoCanView: ['announcementsCreate', 'resourcesCreate'],
    dropMenuGroup: NavItemMenuGroupTypesEnum.announcementsAndReleaseNotes,
    dropMenuGroupName: 'NAV.NEWS',
    isLimitRoleAccessItem: false,
    items: [
      {
        link: 'admin/announcements',
        name: 'NAV.ANNOUNCEMENTS',
        whoCanView: ['announcementsCreate'],
        key: 'admin-announcements',
      },
      {
        link: 'admin/release-notes',
        name: 'NAV.RELEASE_NOTES',
        whoCanView: ['resourcesCreate'],
        key: 'release-notes',
      },
      {
        link: 'admin/knowledge-base/categories',
        name: 'NAV.KNOWLEDGE_BASE',
        whoCanView: ['knowledgeBaseArticleCreate', 'knowledgeBaseCategoryCreate'],
        key: 'admin-knowledge-base',
      },
    ],
  },
  {
    link: 'admin/patient-forms',
    name: 'NAV.PATIENT_FORMS',
    whoCanView: ['patientFormsCreate'],
    key: 'patient-forms',
  },
  {
    link: 'inventory',
    name: 'NAV.INVENTORY',
    whoCanView: ['inventory'],
    key: 'inventory',
  },
  {
    link: 'knowledge-base',
    name: 'NAV.KNOWLEDGE_BASE',
    whoCanView: ['knowledgeBaseUserAccess'],
    key: 'knowledge-base',
  },
  {
    link: 'release-notes',
    name: 'NAV.RELEASE_NOTES',
    whoCanView: ['resourcesList'],
    key: 'release-notes',
  },
  {
    link: 'admin/pharmacies',
    name: 'NAV.PHARMACIES',
    whoCanView: ['patientFormsCreate'],
    key: 'pharmacies',
  },
];
export const rightMenu: IItemMenu[] = [
  {
    link: 'sign-in',
    name: 'NAV.LOGIN',
  },
];
