import { Injectable } from '@angular/core';
import { IPaymentMethodResponseApi } from '@sp-core/models/API-models/payment-method-response-api.interface';
import { IPaymentMethodResponse } from '@sp-core/models/app-models/payment-method-response.interface';
import { ICheckoutResponseApi } from '@sp-core/models/API-models/checkout-response-api.interface';
import { ICheckoutResponse } from '@sp-core/models/app-models/checkout-response.interface';
import { IPendingTransactionApi } from '@sp-core/models/API-models/pending-transaction-api.interface';
import { IPendingTransaction } from '@sp-core/models/app-models/pending-transaction.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentsHttpAdapter {
  public transformToPaymentPublicKeyResponse(data: IPaymentPublicKeyResponseApi): IPaymentPublicKeyResponse {
    return {
      type: data.type,
      publicKey: data.public_key,
    };
  }

  public transformToPaymentMethodResponse(data: IPaymentMethodResponseApi): IPaymentMethodResponse {
    return {
      id: data.id,
      type: data.type,
      isHasPendingTransactions: data.is_has_pending_transactions,
      data: data.data,
      lastPendingTransaction: data?.last_pending_transaction
        ? this.transformToLastPendingTransactionResponse(data.last_pending_transaction)
        : null,
    };
  }

  public transformToLastPendingTransactionResponse(data: IPendingTransactionApi): IPendingTransaction {
    return {
      id: data.id,
      status: data.status,
      paymentDate: data.payment_date,
      amount: data.amount,
    };
  }

  public transformCheckoutResponse(data: ICheckoutResponseApi): ICheckoutResponse {
    return {
      id: data.id,
      status: data.status,
      paymentDate: data.payment_date,
      amount: data.amount,
      paymentMethod: this.transformToPaymentMethodResponse(data.payment_method),
    };
  }
}
