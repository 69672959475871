export enum UserPermissions {
  dashboard = 'dashboard',
  menuHelp = 'menu.help',
  userFilterMale = 'user.filter.male',
  userFilterFemale = 'user.filter.female',
  patientInfo = 'user.patient_info',
  userConsultRequests = 'user.consult_requests',
  userConsultForm = 'user.consult_form',
  profileGet = 'profile.get',
  profileUpdate = 'profile.update',
  calculation = 'calculation',
  calculationGet = 'calculation.get',
  simpleCalculation = 'simple_calculation',
  consultationList = 'consultation.list',
  practiceList = 'practice.list',
  practiceOwn = 'practice.own',
  practiceActiveStatusChange = 'practice.active_status.change',
  reportsList = 'reports.list',
  reportsListFilterPractice = 'reports.list.filter.practice',
  reportsRecommendedDoses = 'reports.get.calculated_doses',
  reportsExport = 'reports.export',
  activityLogList = 'activity_log.list',
  activityLogListFilterPractice = 'activity_log.list.filter.practice',
  activityLogListPracticeName = 'activity_log.get.practice_name',
  rolesAndPermissions = 'roles_and_permissions.get',
  cerboImport = 'cerbo.import',
  cerboIntegrations = 'cerbo.integration',
  contactUsGet = 'contact_us.get',
  termsGet = 'terms.get',
  employeesOwn = 'employee.own',
  employeesList = 'employee.list',
  employeesActiveStatusChange = 'employee.active_status.change',
  employeesCertificatesChange = 'employee.certificates.change',
  employeesInvite = 'invite',
  employeeRolesAssign = 'employee.roles.assign',
  inventory = 'inventory',
  insertionNoteTemplates = 'insertion.note.templates',
  insertionNoteTemplatesOwn = 'insertion.note.templates.own',
  stickyNotesManage = 'sticky_notes.manage',
  insertionUpdate = 'insertion.create',
  siteNoteGet = 'site_note.get',
  siteNoteManage = 'site_note.manage',
  referralsAssign = 'referrals.assign',
  referralsContact = 'referrals.contact',
  medicalQuestionsList = 'medical_questions.list',
  medicalQuestionsCreate = 'medical_questions.create',
  medicalQuestionsMessages = 'medical_questions.messages',
  medicalQuestionsAssign = 'medical_questions.assign',
  medicalQuestionsResolve = 'medical_questions.resolve',
  medicalQuestionsDetails = 'medical_questions.details',
  patientList = 'patient.list',
  registration = 'registration',
  dashboardResearchArticles = 'dashboard.research_articles',
  dashboardMarketing = 'dashboard.marketing',
  dashboardGuidelines = 'dashboard.guidelines',
  passwordRestore = 'password.restore',
  consultationAvailable = 'consultation.available',
  invitationList = 'invitation.list',
  announcementsList = 'announcements.list',
  announcementsCreate = 'announcements.create',
  announcementsStatusUpdate = 'announcements.status.update',
  announcementsNewList = 'announcements.new.list',
  announcementsHistoryList = 'announcements.history.list',
  resourcesList = 'resources.list',
  resourcesCreate = 'resources.create',
  resourcesUpdate = 'resources.update',
  patientFormsCreate = 'patient_forms.create',
  patientFormsUpdate = 'patient_forms.update',
  patientFormsList = 'patient_forms.list',
  limitedRole = 'limited_role',
  pharmacyList = 'pharmacy.list',
  pharmacyCreate = 'pharmacy.create',
  pharmacyUpdate = 'pharmacy.update',
  pharmacyDelete = 'pharmacy.delete',
  pharmacyRequestList = 'pharmacy.request.list',
  pharmacyApplicationList = 'pharmacy.application.list',
  pharmacyApplicationCreate = 'pharmacy.application.create',
  pharmacyApplicationUpdate = 'pharmacy.application.update',
  pharmacyApplicationDelete = 'pharmacy.application.delete',
  reportsPharmacyList = 'reports.pharmacy.list',
  knowledgeBaseCategoryList = 'knowledge_base.category.list',
  knowledgeBaseCategoryCreate = 'knowledge_base.category.create',
  knowledgeBaseCategoryUpdate = 'knowledge_base.category.update',
  knowledgeBaseCategoryDelete = 'knowledge_base.category.delete',
  knowledgeBaseArticleList = 'knowledge_base.article.list',
  knowledgeBaseArticleCreate = 'knowledge_base.article.create',
  knowledgeBaseArticleUpdate = 'knowledge_base.article.update',
  knowledgeBaseArticleDelete = 'knowledge_base.article.delete',
  knowledgeBaseUserAccess = 'knowledge_base.user.access',
  paymentsCreate = 'payments.payment_system.create',
  paymentsList = 'payments.payment_system.list',
  paymentsDetails = 'payments.payment_system.details',
  paymentMethodsCreate = 'payment.payment_methods.create',
  paymentMethodsDetails = 'payment.payment_methods.details',
  paymentMethodsList = 'payment.transactions.list',
  paymentCheckout = 'payment.checkout',
}

export type UserPermissionsType = keyof typeof UserPermissions;
