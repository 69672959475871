import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PaymentTypeMethods } from '../../agreement-keys/payment-type-methods.enum';
import { Observable, throwError } from 'rxjs';
import { ApiConfig } from '../../api-config';
import { PaymentMethod } from '@stripe/stripe-js';
import { IPaymentMethodResponseApi } from '@sp-core/models/API-models/payment-method-response-api.interface';
import { catchError } from 'rxjs/operators';
import { ICheckoutResponseApi } from '@sp-core/models/API-models/checkout-response-api.interface';

@Injectable({
  providedIn: 'root',
})
export class HttpPaymentsService {
  constructor(public readonly _http: HttpClient) {}

  public getPaymentPublicKey(methodType: PaymentTypeMethods): Observable<IPaymentPublicKeyResponseApi> {
    const params: HttpParamsOptions = { type: methodType };
    return this._http.get<IPaymentPublicKeyResponseApi>(`${ApiConfig.paymentsPath}/payment-methods/key`, {
      params,
    });
  }

  public createStripePaymentMethod(paymentData: PaymentMethod): Observable<IPaymentMethodResponseApi> {
    return this._http
      .post<IPaymentMethodResponseApi>(`${ApiConfig.paymentsPath}/stripe/payment-methods`, {
        data: {
          ...paymentData,
        },
      })
      .pipe(
        catchError((errors: HttpErrorResponse) => {
          if (errors.status === 400) {
            if (
              (errors.error as unknown as Stripe3d2SSecureErrorResponseInterface)?.additional_info?.next_action
                ?.redirect_to_url?.url
            ) {
              window.location.href = (errors.error as unknown as Stripe3d2SSecureErrorResponseInterface)
                ?.additional_info?.next_action?.redirect_to_url?.url as any;
            } else {
              const error: Stripe3dSecureErrorResponseInterface =
                errors.error as unknown as Stripe3dSecureErrorResponseInterface;
              window.location.href = error.additional_info?.next_action?.use_stripe_sdk?.stripe_js as any;
            }
            return throwError(() => errors);
          }
          return throwError(() => errors);
        }),
      );
  }

  public getStripePaymentMethod(): Observable<IPaymentMethodResponseApi> {
    return this._http.get<IPaymentMethodResponseApi>(`${ApiConfig.paymentsPath}/stripe/payment-methods`);
  }

  public checkout(paymentId: string, total: string): Observable<ICheckoutResponseApi> {
    return this._http.post<ICheckoutResponseApi>(
      `${ApiConfig.paymentsPath}/stripe/payment-methods/${paymentId}/checkout`,
      {
        total,
      },
    );
  }
}
