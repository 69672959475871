import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  PermissionsService,
  PracticeService,
  RoutingStateService,
  TokenService,
  UserService,
} from '@sp-core-services';
import { InactiveDirective } from '@sp-shared/directives/inactive/inactive.directive';
import { Subject, tap } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ForceLogoutPopupComponent } from '@sp-shared/directives/inactive/containers/force-logout-popup/force-logout-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from '@sp-core/services/reports/reports.service';
import FileUtils from '@sp-helpers/file-utils';
import { AdminRoutsParamsEnum } from '@sp-core/agreement-keys/admin-routs-params.enum';
import { AnnouncementsAgreementsService } from '@sp-core/services/announcements/announcements-agreements.service';
import { Roles } from '@sp-core/agreement-keys/roles.enum';
import { PaymentTypeMethods } from '@sp-core/agreement-keys/payment-type-methods.enum';
import { PaymentService } from '@sp-core/services/payment/payment.service';

@Component({
  selector: 'sp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(InactiveDirective) public spInactive: InactiveDirective;
  public destroy$: Subject<void> = new Subject<void>();
  public inActiveTime = 300; // seconds
  public appHeader: string;
  public noAuthHeader: string;
  public destroyAnnouncementsPopup$: Subject<void> = new Subject<void>();

  constructor(
    private routingState: RoutingStateService,
    public dialog: MatDialog,
    public userService: UserService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private reportsService: ReportsService,
    private router: Router,
    private permissionsService: PermissionsService,
    private practiceService: PracticeService,
    private tokenService: TokenService,
    private announcementsAgreementsService: AnnouncementsAgreementsService,
    private paymentService: PaymentService,
  ) {
    this.routingState.saveRouting().subscribe(() => {});
    this.adminRoutsHandler();
  }

  @HostListener('window:resize', ['$event']) public resize(event: any): void {
    this.changeHeaderImgByScreenResolution(event.target.innerWidth);
  }

  private adminRoutsHandler() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const url = this.authService.prepareAdminPracticeUrl(params);
      if (url) {
        this.navigateTo(url);
        return;
      }
      if (params && params[AdminRoutsParamsEnum.report]) {
        this.downloadReport(params[AdminRoutsParamsEnum.report]);
        this.navigateWithNoParams();
      }
      if (params && params[AdminRoutsParamsEnum.pharmacy_report]) {
        this.downloadPharmacyReport(params[AdminRoutsParamsEnum.pharmacy_report]);
        this.navigateWithNoParams();
      }
      if (params && params[AdminRoutsParamsEnum.practice_export]) {
        this.downloadPracticeReport(params[AdminRoutsParamsEnum.practice_export]);
        this.navigateWithNoParams();
      }
    });
  }

  public ngOnInit(): void {
    this.getToken();
    this.changeHeaderImgByScreenResolution(window.innerWidth);
    this.getCurrentRole();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    this.subscribeToInActive();
    this.initSession();
  }

  public get currentUserRole(): IRole {
    return this.permissionsService.role;
  }

  public changeHeaderImgByScreenResolution(width: number): void {
    if (width <= 1440) {
      this.appHeader = '/assets/images/header-ls.png';
      this.noAuthHeader = '/assets/images/header-welcome-ls.png';
    } else if (width > 1440) {
      this.appHeader = '/assets/images/header-xls.png';
      this.noAuthHeader = '/assets/images/header-welcome-xls.png';
    }
  }

  public subscribeToInActive() {
    if (!this.spInactive) {
      return;
    }
    this.spInactive.inActiveEvent
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          this.spInactive?.stop();
          return this.dialog
            .open(ForceLogoutPopupComponent, {
              disableClose: true,
            })
            .afterClosed();
        }),
      )
      .subscribe((res) => {
        if (!res.logOut) {
          this.spInactive.run();
        } else {
          this.authService.logout().subscribe();
        }
      });
  }

  public initSession(): void {
    this.authService.isActiveSession$.pipe(takeUntil(this.destroy$)).subscribe((isActiveSession) => {
      if (isActiveSession) {
        this.spInactive?.run();
      } else {
        this.spInactive?.stop();
      }
    });
  }

  private navigateTo(url: string): void {
    this.router.navigateByUrl(`/${url}`);
  }

  private navigateWithNoParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
    });
  }

  private downloadReport(processId: string): void {
    this.reportsService.getExportedTable(processId).subscribe((bl: Blob) => {
      FileUtils.saveBlob(bl);
    });
  }

  private downloadPharmacyReport(processId: string): void {
    this.reportsService.getPharmacyExportedTable(processId).subscribe((bl: Blob) => {
      FileUtils.saveBlob(bl);
    });
  }

  private downloadPracticeReport(processId: string): void {
    this.practiceService.getExportedPractice(processId).subscribe((bl: Blob) => {
      FileUtils.saveBlob(bl);
    });
  }

  public getToken(): void {
    this.tokenService.accessToken$
      .pipe(
        tap((token) => {
          if (!token) {
            this.destroyAnnouncementsPopup$.next(null);
            this.destroyAnnouncementsPopup$.complete();
            this.announcementsAgreementsService.cleanStateData();
          } else {
            this.destroyAnnouncementsPopup$ = new Subject<void>();
          }
        }),
        filter((token) => !!token),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.subscribeToHandlerShowAnnouncementsPopups();
        },
      });
  }

  private getCurrentRole(): void {
    this.permissionsService.role$
      .pipe(
        tap(() => {
          if (!this.tokenService.accessToken) {
            this.destroyAnnouncementsPopup$.next(null);
            this.destroyAnnouncementsPopup$.complete();
          }
        }),
        filter((role) => !!role && role.key !== Roles.masterAdmin),
        takeUntil(this.destroy$),
      )
      .subscribe((role) => {
        this.getPaymentPublicKey(role);
        this.getNewAnnouncements();
      });
  }

  public getNewAnnouncements(): void {
    this.announcementsAgreementsService.getNewAnnouncements().pipe(takeUntil(this.destroy$)).subscribe();
  }

  public subscribeToHandlerShowAnnouncementsPopups(): void {
    this.announcementsAgreementsService
      .subscribeToShowAnnouncementsPopup()
      .pipe(takeUntil(this.destroyAnnouncementsPopup$))
      .subscribe();
  }

  public get isOnReleaseNoteRoute(): boolean {
    const url = '/release-notes';
    const urlAdminKnowledgeBase = '/admin/knowledge-base/categories';
    return this.router.url.includes(url);
  }

  public getPaymentPublicKey(role: IRole): void {
    if (role.key === Roles.provider) {
      this.paymentService.getPaymentPublicKey(PaymentTypeMethods.stripe).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }
}
